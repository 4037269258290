<template>
  <div class="right">
    <span class="spen">关注的商品</span>
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="tishi" v-if="list == ''">{{ tishi }}</div>
      <div class="neirong">
        <div class="changgou">
          <div class="list" v-for="(item, index) in list" :key="index">
            <div class="list_T" @mouseover="over(index)" @mouseout="out(index)">
              <div class="img" @click="detail(item.storeId, item.spuId)">
                <Uimage :src="item.spuImg" alt="" />
              </div>
              <div class="position" v-show="activeIndex === index">
                <div class="yuexiao">月销{{ item.saleVolume }}件</div>
                <div class="yigou">已购买{{ item.saleCount }}次</div>
              </div>
              <div class="position1" v-show="activeIndex === index">
                <div class="yuexiao1" @click="home">进入商城</div>
                <div class="yigou1" @click="delattention(item.spuId)">
                  取消关注
                </div>
              </div>
            </div>
            <div class="list_B">
              <div class="title" @click="detail(item.storeId, item.spuId)">
                {{ item.name }}
              </div>
              <div class="price">
                <div class="price_L">
                  ￥<span class="spen">{{ item.price }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fenye" v-if="list != ''">
      <el-pagination
        @current-change="pagechange"
        :current-page="page.current"
        style="text-align: right;margin-top: 20px"
        background
        :total="page.total"
        :page-size="page.size"
        layout="prev, pager, next, jumper, ->, total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import Changgou from '@/components/order/changgou.vue';
import { post, get } from '@/utils/request';
export default {
  components: {
    Changgou,
  },
  data() {
    return {
      activeIndex: -1,
      list: [],
      isLoading: true,
      currentPage: 1,
      totalNum: 1,
      tishi: '空空如也~',
      page: {
        total: 1,
        size: 10,
        current: 1,
      },
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    home() {
      this.$router.push({
        name: 'Home',
      });
    },
    detail(storeId, spuId) {
      const { href } = this.$router.resolve({
        name: 'detail',
        params: {
          //店铺id
          storeId: storeId,
          //商品id
          spu: spuId,
        },
      });
      window.open(href, '_blank');
    },
    pagechange(p) {
      this.page.current = p;
      get(
        'api/collectspu/selColleectSpuList?pageNum=' + p + '&pageSize=8'
      ).then((res) => {
        this.list = res.data.data.list;
      });
    },
    getlist() {
      get('api/collectspu/selColleectSpuList?pageNum=1&pageSize=8').then(
        (res) => {
          this.list = res.data.data.list;
          this.page.total = res.data.data.total;
          this.isLoading = false;
        }
      );
    },
    over(index) {
      this.activeIndex = this.activeIndex == index ? -1 : index;
    },
    out(index) {
      this.activeIndex = this.activeIndex == index ? -1 : index;
    },
    delattention(e) {
      this.$confirm('是否取消关注?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        get('api/collectspu/deleteshopcollectsup?spuId=' + e + '').then(
          (res) => {
            this.getlist();
          }
        );
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tishi {
  width: 90%;
  text-align: center;
  margin-top: 150px;
  font-size: 24px;
  color: rgb(139, 135, 135);
}
.fenye {
  margin-top: 50px;
  margin-bottom: 20px;
}
.right {
  width: 1006px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .qiehuan {
      height: 80px;
      display: flex;
      align-items: center;
      cursor: default;
      .daohang {
        font-size: 15px;
        color: #272727;
        margin-right: 20px;
        cursor: pointer;
      }
      .bian {
        color: #1a4fff;
      }
    }
    .piliang {
      width: 83px;
      height: 23px;
      background: #fff;
      color: #416fff;
      border: 2px solid #416fff;
      border-radius: 5px;
      text-align: center;
      line-height: 23px;
      font-size: 14px;
      margin-right: 28px;
      cursor: pointer;
    }
  }
  .neirong {
    width: 975px;
    margin-top: 13px;
    .changgou {
      width: 975px;
      margin: 0px auto;
      padding-bottom: 50px;
      display: flex;
      flex-wrap: wrap;
      .list {
        width: 234px;
        height: 404px;
        border: 2px solid #f0f5f1;
        margin-top: 10px;
        margin-left: 5px;
        .list_T {
          width: 234px;
          height: 279px;
          position: relative;
          .img {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .position {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #fff;
            .yuexiao {
              width: 114px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              background: #9ba2a8;
            }
            .yigou {
              width: 115px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              background: #9ba2a8;
            }
          }
          .position1 {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #fff;
            .yuexiao1 {
              width: 114px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              background: #9ba2a8;
              cursor: pointer;
            }
            .yigou1 {
              width: 115px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              background: #9ba2a8;
              cursor: pointer;
            }
          }
        }
        .list_B {
          padding: 0 15px;
          .title {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-top: 20px;
            font-size: 14px;
            color: #333;
            cursor: pointer;
          }
          .price {
            height: 25px;
            line-height: 25px;
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            .price_L {
              color: #e88021;
              font-size: 12px;
              .spen {
                font-size: 14px;
                color: #e88021;
              }
            }
            // .price_R {
            //   width: 110px;
            //   background: #1850ff;
            //   color: #fff;
            //   border-radius: 25px;
            //   display: flex;
            //   justify-content: space-between;
            //   align-items: center;
            //   .jiaru_L {
            //     width: 20px;
            //     height: 20px;
            //     border-radius: 50%;
            //     background: #fff;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     margin-left: 5px;
            //     img {
            //       width: 14px;
            //       height: 12px;
            //     }
            //   }
            //   .jiarucart_R {
            //     transform: scale(0.7);
            //     flex: 1;
            //     width: 70px;
            //     white-space: nowrap;
            //   }
            // }
          }
        }
      }
    }
  }
}
</style>
